import { Product, ProductPricing } from './types/product';
import { definedNumber, roundNumber } from './numbers';
import {
  calculateOrderedUnitPrice,
  convertPricingToUseOnlyPricedUnit,
} from './pricing';

export interface OrderLine {
  pricingAtTimeOfOrder: ProductPricing;
  nrOfUnits: number;
  nrOfOrderedUnitsDelivered?: number;
  nrOfPricedUnitsDelivered?: number;
  isExemptFromTransactionCut?: boolean;
}

export interface OrderLineWithProduct extends OrderLine {
  product: Product;
}

export interface CountPriceUnit {
  count: number;
  price: number;
  unit: string;
}

export const setNrOfUnits = <T extends OrderLine>(orderLine: T): T => {
  const {
    pricingAtTimeOfOrder,
    nrOfUnits,
    nrOfOrderedUnitsDelivered,
    nrOfPricedUnitsDelivered,
  } = orderLine;

  if (definedNumber(nrOfPricedUnitsDelivered)) {
    return {
      ...orderLine,
      pricingAtTimeOfOrder: convertPricingToUseOnlyPricedUnit(
        pricingAtTimeOfOrder
      ),
      nrOfUnits: nrOfPricedUnitsDelivered,
      nrOfOrderedUnitsDelivered: undefined,
      nrOfPricedUnitsDelivered: undefined,
    };
  }

  return {
    ...orderLine,
    nrOfUnits: definedNumber(nrOfOrderedUnitsDelivered)
      ? nrOfOrderedUnitsDelivered
      : nrOfUnits,
    nrOfOrderedUnitsDelivered: undefined,
    nrOfPricedUnitsDelivered: undefined,
  };
};

type KeyingFunction = (orderLine: OrderLineWithProduct) => string;
export const defaultKeyFunction: KeyingFunction = (orderLine) => {
  const {
    product,
    pricingAtTimeOfOrder: {
      pricedUnit,
      orderedUnit,
      pricedUnitsPerOrderedUnit,
    },
  } = orderLine;
  const { _id: productId, name, type } = product;

  return `${name}${type}${productId}${pricedUnit}${orderedUnit}${pricedUnitsPerOrderedUnit}`;
};

export const withPriceKeyFunction: KeyingFunction = (orderLine) => {
  const {
    pricingAtTimeOfOrder: { nokPerPricedUnit },
  } = orderLine;
  return `${defaultKeyFunction(orderLine)}${nokPerPricedUnit}`;
};

export type KeyingFunctionName = 'DEFAULT' | 'WITH_PRICE';

export const getKeyFunction = (
  name: KeyingFunctionName = 'DEFAULT'
): KeyingFunction => {
  switch (name) {
    case 'WITH_PRICE':
      return withPriceKeyFunction;
    case 'DEFAULT':
    default:
      return defaultKeyFunction;
  }
};

export const getCountAndPriceAndUnit = ({
  pricingAtTimeOfOrder,
  nrOfPricedUnitsDelivered,
  nrOfOrderedUnitsDelivered,
  nrOfUnits,
}: OrderLine): CountPriceUnit => {
  const { nokPerPricedUnit, pricedUnit, orderedUnit } = pricingAtTimeOfOrder;

  if (definedNumber(nrOfPricedUnitsDelivered))
    return {
      count: nrOfPricedUnitsDelivered,
      price: nokPerPricedUnit,
      unit: pricedUnit,
    };

  return {
    count: definedNumber(nrOfOrderedUnitsDelivered)
      ? nrOfOrderedUnitsDelivered
      : nrOfUnits,
    price: calculateOrderedUnitPrice(pricingAtTimeOfOrder),
    unit: orderedUnit,
  };
};

export const convertToPricedUnitsDelivered = (
  orderLine: OrderLine
): OrderLine => {
  const {
    pricingAtTimeOfOrder,
    nrOfPricedUnitsDelivered,
    nrOfOrderedUnitsDelivered,
    nrOfUnits,
  } = orderLine;
  if (definedNumber(nrOfPricedUnitsDelivered)) return orderLine;

  const { pricedUnitsPerOrderedUnit } = pricingAtTimeOfOrder;

  const orderedUnitsDelivered = definedNumber(nrOfOrderedUnitsDelivered)
    ? nrOfOrderedUnitsDelivered
    : nrOfUnits;

  const calculatedPricedUnitsDelivered = roundNumber(
    orderedUnitsDelivered * pricedUnitsPerOrderedUnit
  );

  return {
    ...orderLine,
    nrOfOrderedUnitsDelivered: undefined,
    nrOfPricedUnitsDelivered: calculatedPricedUnitsDelivered,
  };
};

export const getCountAndPriceAndUnitAsPricedUnits = (
  orderLine: OrderLine
): CountPriceUnit => {
  const convertedOrderLine = convertToPricedUnitsDelivered(orderLine);
  return getCountAndPriceAndUnit(convertedOrderLine);
};
