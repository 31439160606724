import { ProductPricing } from './types/product';
import { roundNumber } from './numbers';

export const calculateOrderedUnitPrice = ({
  nokPerPricedUnit,
  pricedUnitsPerOrderedUnit,
}: ProductPricing): number =>
  roundNumber(nokPerPricedUnit * pricedUnitsPerOrderedUnit);

export const convertPricingToUseOnlyPricedUnit = ({
  pricedUnit,
  nokPerPricedUnit,
}: ProductPricing): ProductPricing => ({
  pricedUnit,
  orderedUnit: pricedUnit,
  nokPerPricedUnit,
  pricedUnitsPerOrderedUnit: 1,
});

export const pricingUnitsEquals = (
  pricing1: ProductPricing,
  pricing2: ProductPricing
): boolean =>
  pricing1.orderedUnit === pricing2.orderedUnit &&
  pricing1.pricedUnit === pricing2.pricedUnit;
