const convertFloat = (val: number): number => parseFloat(val.toFixed(6));

export const multiply = (a: number, b: number): number => {
  const aFloat = convertFloat(a);
  const bFloat = convertFloat(b);
  return convertFloat(aFloat * bFloat);
};

export const roundNumber = (val: number, fractions = 2): number => {
  if (Number.isInteger(val)) return val;
  const factor = Math.pow(10, fractions);

  const rounded = Math.round(Math.abs(multiply(val, factor))) / factor;
  return val < 0 ? -1 * rounded : rounded;
};

export const definedNumber = (val?: number): boolean =>
  Boolean(val) || val === 0;

export const definedNumberOrFallback = <T>(fallback: T) => (
  val?: number
): number | T => (definedNumber(val) ? val : fallback);
