export enum CountryCode {
  NO = 'no',
  DK = 'dk',
  OTHER = 'other',
}

export enum VatDecimal {
  FIFTEEN = 0.15,
  TWENTY_FIVE = 0.25,
}

interface Address {
  addressLine1: string;
  addressLine2?: string;
  postalCode: string;
  city: string;
  country: CountryCode;
}

export interface Organization {
  name: string;
  number: string;
  address: Address;
}

export const getCountry = (organization: Organization): CountryCode =>
  organization.address.country;

export const isCountry = (
  organization: Organization,
  country: CountryCode
): boolean => organization.address.country === country;

export const getDefaultVatDecimal = (organization: Organization): number => {
  const {
    address: { country },
  } = organization;
  switch (country) {
    case CountryCode.NO:
      return VatDecimal.FIFTEEN;
    case CountryCode.DK:
      return VatDecimal.TWENTY_FIVE;
    default:
      return VatDecimal.FIFTEEN;
  }
};

const dagensOrganizations: { [key in CountryCode]?: Organization } = {
  [CountryCode.DK]: {
    name: 'Dagens ApS',
    number: '42140066',
    address: {
      addressLine1: 'Flæsketorvet 68',
      postalCode: '1711',
      city: 'København V',
      country: CountryCode.DK,
    },
  },
  [CountryCode.NO]: {
    name: 'Dagens AS',
    number: '819 443 602',
    address: {
      addressLine1: 'Storgata 36C',
      postalCode: '0182',
      city: 'Oslo',
      country: CountryCode.NO,
    },
  },
};

export const formatBusinessRegistrationText = (
  organization: Organization
): string => {
  const {
    address: { country },
    number: number,
  } = organization;
  switch (country) {
    case CountryCode.DK:
      return `CVR nr: ${number}`;
    case CountryCode.NO:
    default:
      return `Foretaksregisteret ${number} MVA`;
  }
};

export const getLocalDagensOrganization = (
  organization: Organization
): Organization => dagensOrganizations[getCountry(organization)];
