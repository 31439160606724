export enum MeasureUnit {
  kg = 'kg',
  liter = 'liter',
}

export enum CombinedUnit {
  pcs = 'stk',
}

export enum PackedUnit {
  package = 'pakke',
  crate = 'kasse',
  tray = 'brett',
}

export const Unit = {
  ...MeasureUnit,
  ...CombinedUnit,
  ...PackedUnit,
};

export enum ScaledMeasureUnit {
  g = 'g',
  ml = 'ml',
}

export type InputMeasureUnit = ScaledMeasureUnit | MeasureUnit;

export type UnitType = MeasureUnit | CombinedUnit | PackedUnit;
