export const onboardingSteps = {
  ADD_FIRST_PRODUCT: 'addFirstProduct',
  RECEIVE_FIRST_ORDER: 'receiveFirstOrder',
  DELIVER_FIRST_ORDER: 'deliverFirstOrder',
  RECEIVE_FIRST_PAYMENT: 'receiveFirstPayment',
};

export const initialOnboardingSteps = Object.values(onboardingSteps);

export const forSaleStatuses = {
  FOR_SALE: 'FOR_SALE',
  ON_REQUEST: 'ON_REQUEST',
  NOT_FOR_SALE: 'NOT_FOR_SALE',
};

export enum AvailableLanguages {
  NORWEGIAN = 'nb',
  ENGLISH = 'en',
  DANISH = 'da',
}

export const ConsumerOnboarding = {
  HAS_SEEN_INTRO_SCREEN: 'hasSeenIntroScreen',
} as const;

export type ConsumerOnboardingStepKey = keyof typeof ConsumerOnboarding;
export type ConsumerOnboardingStepValue = typeof ConsumerOnboarding[ConsumerOnboardingStepKey];
